<template>
  <v-btn :id="id" :class="typeClass" :disabled="disabled" @click="onClick()">
    <slot> </slot>
  </v-btn>
</template>
<script>
import { uniqueId } from 'lodash';

export default {
  name: 'AGButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: uniqueId('ag_btn_'),
    },

    type: {
      type: String,
      required: false,
    },
  },

  computed: {
    typeClass() {
      let typeClass = 'btn-ag-primary';
      switch (this.type) {
        case 'primary':
          typeClass = 'btn-ag-primary';
          break;
        case 'secondary':
          typeClass = 'btn-ag-secondary';
          break;
        case 'tertiary':
          typeClass = 'btn-ag-tertiary';
          break;
        default:
          break;
      }
      return typeClass;
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="scss"></style>
