var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      class: _vm.typeClass,
      attrs: { id: _vm.id, disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }